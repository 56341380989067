<template>
  <div class="login"></div>
</template>
<script>
function getCookie() {//获取cookie数据，返回值为cookie键值组成的json对象
  let jso = {};
  let arr = [];
  let coo = document.cookie;
  arr = coo.split("; ");
  arr.forEach(function (x) {
    let arr2 = x.split("=");
    jso[arr2[0]] = arr2[1];
  });
  return jso;
}
function setCookie(key, value, time) {//新增/修改cookie值，key为键名，value为值，time为是否设置长生命周期的cookie
  if (time == undefined) {
    document.cookie = key + "=" + value;
  } else {
    let date = new Date();
    date.setDate(date.getDate() + time);
    document.cookie = key + "=" + value + ";expires = " + date;
  }
}
export default {
  data() {
    return {};
  },
  created() {
    if (getCookie().audioPlay) {
      setCookie("audioPlay", "", -1);
    }
    if (
      this.$route.query.accessToken == undefined ||
      this.$route.query.accessToken == "" ||
      this.$route.query.accessToken == null
    ) {
      this.$router.replace({
        path: "/login"
      });
    } else {
      sessionStorage.setItem("token", this.$route.query.accessToken);
      // 用户信息
      this.$http({
        method: "get",
        url: "/api/system/home/wxUserInfo"
      })
        .then(res => {
          this.$store.state.user.loginState = true;
          let scope = {
            0: false,
            1: false,
            2: false,
            3: true,
            4: false
          };
          if (
            res.data.data.organizationInfo.scope != "" &&
            res.data.data.organizationInfo.scope != null
          ) {
            res.data.data.organizationInfo.scope.split(",").forEach(x => {
              if (x == "境内") {
                scope[0] = true;
              }
              if (x == "境外") {
                scope[1] = true;
              }
              if (x == "跟评") {
                scope[2] = true;
              }
            });
          }
          if (res.data.data.organizationInfo.flag == 1) {
            scope[3] = false;
          }
          // 视频权限
          this.$http({
            method: "get",
            url: "/api/msgquery/sentiment/videoType?organId=" + res.data.data.organizationInfo.organizationId
          }).then(res2 => {
            if (res2.data.data.videoType == 1) {
              scope[4] = true;
            }
            let data = {
              organizationId: res.data.data.organizationInfo.organizationId,
              name: res.data.data.name,
              versions: "数解舆情 V4.0",
              mechanism: res.data.data.organizationInfo.organizationName,
              loginTime: res.data.data.logonTime,
              loginState: true,
              organType:
                res.data.data.organizationInfo.broadHeading +
                res.data.data.organizationInfo.subclass,
              userId: res.data.data.organizationInfo.userId,
              areaId: res.data.data.organizationInfo.areaId,
              phone: res.data.data.organizationInfo.phoneNumber,
              upDispaly: res.data.data.organizationInfo.preSystemName,
              customerId: res.data.data.customerId,
              isreport: res.data.data.organizationInfo.isReport,
              organizationTypeId:
                res.data.data.organizationInfo.organizationTypeId,
              scope: scope,
              keywordLimit: res.data.data.organizationInfo.keywordLimit,
              topicLimit: res.data.data.organizationInfo.topicLimit,
              logoUrl: res.data.data.organizationInfo.logoUrl,
              broadHeading: res.data.data.organizationInfo.broadHeading,
              tokenTypeWeb: "!",
              servicePeriod: res.data.data.organizationInfo.servicePeriod,
              // 是否为虚拟用户
              isVirtual: res.data.data.isVirtual,
              abbreviation: res.data.data.organizationInfo.abbreviation,
              unionid: res.data.data.unionid
            };
            sessionStorage.setItem("user", JSON.stringify(data));
            this.$store.state.user = data;
            //跳转话题
            if (this.$route.query.callbackType == 'topic') {
              window.open(
                `#/topics/index/topicList/topicListDetails?id=${this.$route.query.id}&topicId=${this.$route.query.topicId}&type=history&topicType=${this.$route.query.topicType}&isDeepUpdate=${this.$route.query.isDeepUpdate}&isPause=${this.$route.query.isPause}`,
                '_self'
              );
              return false;
            }
            // 大屏跳转
            if (sessionStorage.getItem("bigUrl")) {
              let bigUrl = sessionStorage.getItem("bigUrl");
              if (sessionStorage.getItem("bigType")) {
                sessionStorage.removeItem("bigUrl");
                sessionStorage.removeItem("bigType");
                window.open(
                  bigUrl + `?Token=${this.$route.query.accessToken}&TopicID=0`,
                  "_self"
                );
              } else {
                sessionStorage.removeItem("bigUrl");
                window.open(
                  bigUrl +
                  `?AgencyID=${res.data.data.organizationInfo.organizationId}&Token=${this.$route.query.accessToken}&AreaID=${res.data.data.organizationInfo.areaId}`,
                  "_self"
                );
              }
              return false;
            }
            if (window.location.hash.split("routers=").length > 1) {
              window.open(
                decodeURIComponent(window.location.hash.split("routers=")[1]),
                "_self"
              );
            } else if (this.$route.query.goto) {
              this.$router.replace({
                path: "/" + this.$route.query.goto
              });
            } else {
              if (this.$route.query.toType) {//从新版大屏跳转过来的
                console.log(this.$route.query.toType);
                if (this.$route.query.toType == '1') {
                  this.$router.replace({
                    path: "/message/index",
                    query: {
                      toType: 1,
                      // topicId: this.$route.query.topicId,
                    }
                  });
                }
                if (this.$route.query.toType == '2') {
                  this.$router.replace({
                    path: "/sentiment/index",
                    query: {
                      toType: 2,
                      // topicId: this.$route.query.topicId,
                    }
                  });
                }
                if (this.$route.query.toType == '3') {
                  this.$router.replace({
                    path: "/sentiment/index",
                    query: {
                      toType: 3,
                      // topicId: this.$route.query.topicId,
                    }
                  });
                }
                if (this.$route.query.toType == '4') {
                  this.$router.replace({
                    path: "/sentiment/index",
                    query: {
                      toType: 4,
                      // topicId: this.$route.query.topicId,
                    }
                  });
                }
                if (this.$route.query.toType == '5') {
                  this.$router.replace({
                    path: "/topics/index/topicList/topicListDetails",
                    query: {
                      id:this.$route.query.id,
                      topicId: this.$route.query.topicId,
                      topicType: this.$route.query.topicType,
                      isDeepUpdate: this.$route.query.isDeepUpdate,
                      isPause: this.$route.query.isPause,
                      type:'index'
                    }
                  });
                }
                
              } else {
                this.$router.replace({
                  path: "/index"
                });
              }
            }
          })
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  methods: {},
  mounted() { }
};
</script>
<style scoped></style>